/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0;padding:0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color:rgb(255, 255, 241);}

.map-info-window-content b{
    margin: 8px 12px;
    font-size: 1rem;
}

.map-container {
    height: 600px !important;
    width: auto !important;
  }